@keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0) scaleX(3);
  }

  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0) scaleX(1);
  }

  75% {
    transform: translate3d(-10px, 0, 0) scaleX(0.98);
  }

  90% {
    transform: translate3d(5px, 0, 0) scaleX(0.995);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, 50px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeInDownToast {
  from {
    opacity: 0;
    transform: translate3d(0, 150px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(50px, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}


@keyframes fadeOutUp {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: translate3d(0, -500%, 0);
  }
}

.fadeOutUp {
  animation: fadeOutUp 800ms ease forwards !important;
}

.fadeInLeft {
  opacity: 0;
  animation: fadeInLeft 800ms ease forwards !important;
}

.fadeInDown {
  opacity: 0;
  animation: fadeInDown 800ms ease forwards !important;
}

.bounceInLeft {
  animation: bounceInLeft 800ms ease forwards !important;
}

.fadeIn {
  opacity: 0;
  animation: fadeIn 800ms ease forwards !important;
}


@for $i from 1 through 10 {
  .animate-delay-#{$i} {
    animation-delay: #{$i * 200}ms !important;
  }
}


.typing {
  height: 21px;
  @extend .row-center;
}

.typing__dot {
  float: left;
  width: 8px;
  height: 8px;
  margin: 0 3px;
  background: $secondary;
  border-radius: 50%;
  opacity: 0;
  animation: loadingFade 1400ms infinite;
}

.typing__dot:nth-child(1) {
  animation-delay: 0s;
}

.typing__dot:nth-child(2) {
  animation-delay: 0.2s;
}

.typing__dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes loadingFade {
  0% {
    opacity: 0.5;
    transform: scale(1);
  }
  30% {
    opacity: 1;
    transform: scale(1.3);
  }
  60% {
    opacity: 0.5;
    transform: scale(1);
  }
  100% {
    opacity: 0.5;
    transform: scale(1);
  }
}