
.card {
  transition: 300ms;
  border: 1px solid;
  @include themed() {
    background: t($front-bg);
    border-color: t("border");
  };
}
.card-title {
  font-weight: bold;
}

.filter-card {
  max-width: 800px;
  // margin: auto;
  @extend .card;
  border-radius: $border-radius !important;
  // transform: translateY(-50%);
  .btn {
    border-radius: 0 $border-radius $border-radius 0;
    background: linear-gradient(71deg, $red, $orange);
    border: 0;
    transition: 200ms;
    font-weight: bold;
    color: white;
    flex: 1;
    &:hover {
      background: linear-gradient(71deg, #d94d49, #d86057);
    }
  }
  .divider {
    width: 1px;
    height: 60px;
    background-color: #e9eef1;
  }
}

.card-selection-header {
  margin: -1rem -1rem 0 -1rem;
  padding: 1rem;
  background-color: rgba($blue, 0.2);
  border-radius: $border-radius $border-radius 0 0;
}

.yard-card {
  width: 310px;
  min-width: 310px;
  @include themed() {
      background: t($front-bg);
    };
  padding: 1rem 1.5rem;
  cursor: pointer;
  border-radius: $border-radius;
  display: flex;
  flex-direction: column;
}

.login-card {
  z-index: 10;
  @include themed() {
    background: t($front-bg);
  };
}