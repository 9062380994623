.room {
  padding: 1rem;
  cursor: pointer;
  border-radius: $border-radius;
  &.active {
    background-color: $gray-200;
  }
}

.chat-date-label {
  color: $secondary;
  font-size: $font-size-sm;
  text-align: center;
  margin-bottom: 1rem;
}

.message {
  border-radius: $border-radius;
  padding: 0.5rem 1rem;
  word-wrap: break-word;
  max-width: 70%;
  &.only-emojis {
    font-size: 28px;
    background-color: transparent !important;
    padding: 0.5rem 0 !important;
  }
}
.own-message {
  display: flex;
  flex-direction: row-reverse;
  padding: 0 1rem 0.25rem 1rem;
  &:hover .message-menu {
    display: block;
    margin-right: 1rem;
  }
  .message {
    color: white;
    background-color: $primary;
  }
}
.remote-message {
  display: flex;
  flex-direction: row;
  padding: 0 1rem 0.25rem 0;
  .message {
    @include themed() {
      background: t($bg);
    };
    box-shadow: $box-shadow-sm;
  }
}