.base-toaster {
  position: fixed;
  left: 1rem;
  right: 1rem;
  top: 8rem;
  z-index: 9999;
}

.toast {
  background: $primary;
  width: 100%;
  max-width: 450px;
  margin: auto;
  color: white;
  font-weight: bold;
  border-radius: 0.5rem;
  animation: fadeInDownToast 500ms ease;
  .toast-body {
    padding: 1rem 2rem;
  }
}

.call-dialog {
  position: fixed;
  right: 2rem;
  bottom: 1rem;
  z-index: 9999;
}

.call-dialog-body {
  background: white;
  box-shadow: $box-shadow-lg;
  width: 300px;
  margin: auto;
  border-radius: 0.5rem;
}
