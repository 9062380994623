$navbar-width: 250px;
$navbar-width-minimize: 60px;
// Typography
$font-family-sans-serif: 'Roboto', sans-serif;
$font-size-base: 0.9rem;
$font-size-sm: $font-size-base * .825;
$font-size-lg: $font-size-base * 1.3;

$container-max-widths: (
  sm: 540px,
  md: 1090px,
  lg: 1180px,
  xl: 1280px
) !default;

$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * 0.25,
  2: $spacer * 0.5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 2,
  6: $spacer * 2.5,
  7: $spacer * 3,
  8: $spacer * 4,
  9: $spacer * 5,
  10: $spacer * 6,
  11: $spacer * 7,
);
$width-values: (
  1: $spacer,
  2: $spacer * 2,
  3: $spacer * 3 ,
  4: $spacer * 4,
  5: $spacer * 5,
  6: $spacer * 6,
  7: $spacer * 7,
  8: $spacer * 8,
  9: $spacer * 9,
  10: $spacer * 10,
);

$gray-100: #f4f7fa;
$gray-200: #edf0f6;
$gray-300: #e1e3ed;
$gray-400: rgb(205 210 220);
$gray: #8E959D;
$gray-600: #757D86;
$gray-700: #616161;
$gray-800: #414B57;
$gray-900: #4c546f;
$dark: $gray-900;

$saturate-gray: #8e9fb1;

$light-beige: #f2efe6;

// $primary:     #31b7be;
$primary:     #4d9cc1;

$secondary:     $gray;
$success:       #2dc191;
$info:          #7775f3;
$warning:       #f19e57;
$danger:        #de6638;


$google-red: #e44f3a;
$facebook: #4267b2;

$sidebar-color: $dark;

$box-shadow:                  0 .5rem 1rem rgba($dark, .15) !default;
$box-shadow-sm:               0 .125rem .25rem rgba($dark, .075) !default;
$box-shadow-lg:               0 1rem 3rem rgba($dark, .175) !default;
$box-shadow-inset:            inset 0 1px 2px rgba($dark, .075) !default;

$shadow: 0 3px 25px 0 rgba(62, 69, 80, 0.12), 0 2px 5px 0 rgb(62 69 80 / 10%);

$height-element: 40px;

$sidebar-width: 260px;
$admin-navbar-height: 65px;

$border-radius-sm: 0;
$border-radius: 0.25rem;
$border-radius-lg: 3rem;
$modal-content-border-radius: 0;

$enable-shadows: true;
$enable-negative-margins: true;
$enable-smooth-scroll: false;
// $enable-gradients: true;

// Layout
$body-color: black;
$grid-gutter-width: 2rem;


// Tabs
$nav-tabs-border-color: transparent;
$nav-tabs-border-width: 3px;
$nav-tabs-link-active-border-color: $primary;

// Dropdown
$dropdown-spacer:            1rem;
$dropdown-border-width:      0;
$dropdown-box-shadow:        0 .5rem 1rem rgba($dark, .175);
$dropdown-link-active-color: inherit;
$dropdown-link-active-bg:    inherit;
$dropdown-item-padding-y:    .5rem !default;
$dropdown-item-padding-x:    1.75rem !default;

$padding-y: 0.5rem;
$padding-x: 0.75rem;

// Button
$btn-padding-x: 1rem;
$btn-padding-y: $padding-y;
$btn-padding-y-sm: 0.375rem;
$btn-padding-x-sm: 0.5rem;
$btn-padding-y-lg: 1rem;
$btn-padding-x-lg: 2rem;
$btn-border-width: 1px;
$btn-font-weight: bold;
$btn-box-shadow: 0;

$btn-border-radius:           $border-radius;
$btn-border-radius-sm:        $border-radius-sm;
$btn-border-radius-lg:        $border-radius;

$btn-box-shadow:              none;
$btn-focus-box-shadow:        none;
$btn-active-box-shadow:       none;

$btn-font-size-lg:            $font-size-lg;

// Input
$input-border-width: 1px;
$input-border-color: $gray-400;
// $input-bg: white;
$input-box-shadow: 0;
$input-padding-y: $padding-y;
$input-padding-x: $padding-x;

$input-focus-box-shadow: none;
$input-placeholder-color: $gray;

$input-padding-y-lg:                    1rem;
$input-padding-x-lg:                    1.5rem;
$input-font-size-lg:                    $font-size-lg;

$input-border-radius:                   $border-radius;
$input-border-radius-sm:                none;
$input-border-radius-lg:                none;

$form-label-margin-bottom:              .25rem;
$form-label-font-size:                  $font-size-sm;
$form-label-color:                      $saturate-gray;

// Card
$card-border-width: 0;
$card-cap-bg: transparent;

// Modal
$modal-content-border-width: 0;
$modal-header-padding: 1.5rem 1.5rem 0 2rem;
$modal-inner-padding: 2rem;

$modal-fade-transform:              scale(0.5);
$modal-transition:                  transform .2s ease-in-out;


// Table {

$grid-columns: 12;


// Progress
$progress-height:                   0.5rem;
// $progress-font-size:                $font-size-base * .75;
// $progress-bg:                       transparent;
// $progress-border-radius:            $border-radius;
// $progress-box-shadow:               none;
// $progress-bar-color:                $primary;
// $progress-bar-bg:                   $gray-100;
// $progress-bar-animation-timing:     6s linear infinite;
// $progress-bar-transition:           width 6s linear;

// BADGE
// $badge-font-size:                   .75em !default;
// $badge-font-weight:                 $font-weight-bold !default;
// $badge-color:                       $white !default;
// $badge-padding-y:                   .35em !default;
// $badge-padding-x:                   .65em !default;
// $badge-border-radius:               $border-radius !default;
$table-border-width: 0;
$table-cell-vertical-align: middle;


$themes: (
  default: (
    bg: hsl(200 21% 96% / 1),
    front-bg: white,
    light-bg: $gray-100,
    light-bg2: $gray-200,
    light-bg3: $gray-300,
    navbar-text: $gray,
    border: $gray-300,
    text: $dark,
  ),
  dark: (
    bg: #1e1d2b,
    front-bg: #252736,
    light-bg: #2f3042,
    light-bg2: #2f3042,
    light-bg3: $gray-800,
    navbar-text: #66677c,
    border: #2f3042,
    text: white,
  ),
);

$bg: 'bg';
$front-bg: 'front-bg';
$light-bg: 'light-bg';
$navbar-text: 'navbar-text';
$text: 'text';

@mixin themed() {
  @each $theme, $map in $themes {
    .theme--#{$theme} & {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), '#{$key}');
        $theme-map: map-merge($theme-map, ($key: $value)) !global;
      }
      @content;
      $theme-map: null !global;
    }
  }
}

@function t($key) {
  @return map-get($theme-map, $key);
}

body.theme--default {
  background-color: hsl(200 21% 96% / 1);
  color: $dark;
}
body.theme--dark {
  background-color: #1e1d2b;
  color: white;
}