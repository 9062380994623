
.navbar {
  width: $navbar-width;
  transition: 300ms;
  display: flex;
  position: fixed;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  @include themed() {
    background: t($front-bg);
  }
  padding: 0 1.5rem;
  a {
    font-weight: bold;
    &.active {
      // border-right: 3px solid $primary;
      .sidebar-link {
        color: $primary !important;
        @include themed() {
          background-color: t($light-bg);
        }
        // background-color: rgba($primary, 0.1);
      }
    }
    .sidebar-link {
      margin-bottom: 0.5rem;
      @include themed() {
        color: t($navbar-text);
      }
      &:hover {
        @include themed() {
          background-color: t($light-bg);
        }
      }
      i {
        font-size: 1.25rem;
      }
    }
  }

}
.mobile-navbar {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: white;
  z-index: 100;
  height: 70px;
  @extend .row-around;
  box-shadow: $shadow;
  .mobile-navbar-btn {
    font-size: 1.25rem;
    color: $dark;
    width: 50px;
    height: 50px;
    display: flex;
    border-radius: $border-radius;
    justify-content: center;
    align-items: center;
  }
  .active .mobile-navbar-btn {
    background-color: rgba($primary, 0.1);
    color: $primary;
  }
}