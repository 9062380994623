.loader-container {
  width: 160px;
  height: 60px;
  position: absolute;
  left: 50%;
  top: 1rem;
  transform: translateX(-50%);
  @extend .card;
  color: $primary;
  .loader {
    border: .2em solid currentcolor;
    border-bottom-color: transparent;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    animation: 1s loader linear infinite;
    position: relative;
  }

  @keyframes loader {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

.circle-loader {
  margin: auto;
  fill: transparent;
  stroke: $primary;
  stroke-width: 5;
  animation: dash 2s ease infinite,rotate 2s linear infinite;
}

@keyframes dash {
0% {
  stroke-dasharray: 1,95;
  stroke-dashoffset: 0;
}
50% {
  stroke-dasharray: 85,95;
  stroke-dashoffset: -25;
}
100% {
  stroke-dasharray: 85,95;
  stroke-dashoffset: -93;
}
}

@keyframes rotate {
0% {transform: rotate(0deg); }
100% {transform: rotate(360deg); }
}


.loading-2 {
  $duration: .7s;
  $size: 1.25em;
  .spinner * {
    box-sizing: border-box;
  }
  .spinner {
    display: inline-block;
    animation-name: anim-spinner;
    animation-duration: $duration;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    .circle {
      width: $size;
      height: ($size/2);
      overflow: hidden;
    }
    .circle-inner {
      transform: rotate(45deg);
      border-radius: 50%;
      border: ($size/8) solid white;
      border-right: ($size/8) solid transparent;
      border-bottom: ($size/8) solid transparent;
      width: 100%;
      height: 200%;
      animation-name: anim-circle-1;
      animation-duration: $duration;
      animation-iteration-count: infinite;
      animation-direction: alternate;
      animation-timing-function: cubic-bezier(.25, .1, .5, 1);
      &.dark {
        border-top-color: $dark;
        border-left-color: $dark;
      }
      &.primary {
        border-top-color: $primary;
        border-left-color: $primary;
      }
    }
    .circle-2 {
      transform: rotate(180deg);
      & .circle-inner {
        animation-name: anim-circle-2;
      }
    }
    @keyframes anim-circle-1 {
      from {
        transform: rotate(60deg);
      }
      to {
        transform: rotate(205deg);
      }
    }
    @keyframes anim-circle-2 {
      from {
        transform: rotate(30deg);
      }
      to {
        transform: rotate(-115deg);
      }
    }
    @keyframes anim-spinner {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
  }
}