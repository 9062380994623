@include media-breakpoint-down(md) {
  .container {
    max-width: 940px;
  }
}
@include media-breakpoint-up(md) {
  .container-fluid {
    padding: 0 2rem;
  }
}

.responsible-menu {
  width: 50px;
  i {
    font-size: 1.25rem;
  }
}


* {
  &::-webkit-scrollbar {
    width: 9px;
    height: 9px;
  }

  &::-webkit-scrollbar-track {
    @include themed() {
      background: t("light-bg2");
    }
  }

  &::-webkit-scrollbar-thumb {
    @include themed() {
      background: t("light-bg3");
    }
    border-radius: $border-radius;
  }

  &::-webkit-scrollbar-thumb:hover {
    @include themed() {
      background: t("navbar-text");
    }
  }
}

.app-sidebar {
  animation: appsidebar-right 400ms ease-in-out;
  // right: 0;
  // position: fixed;
  // margin-left: auto;
  overflow: auto;
  width: 100%;
  // z-index: 100000;
  // top: 0;
  // bottom: 0;
  @include themed() {
    background: t($front-bg);
  };
  max-width: 700px;
  height: 100vh;
  .app-sidebar-close {
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
  }
  .app-sidebar-header {
    @extend .row-between;
    // padding: 1.5rem;
    // color: white;
    font-size: 1.5rem;
    // background-color: $dark;
  }
  .app-sidebar-body {
    display: flex;
    flex-direction: column;
    min-height: 100%;
  }
  .sidebar-divider {
    border-bottom: 1px solid;
    @include themed() {
      border-color: t($light-bg);
    };
    margin: 1rem -1.5rem;
  }
  .error-sidebar-container {
    position: absolute;
    top: 0;
    bottom: 0;
    align-items: center;
    display: flex;
    justify-content: center;
    transition: 500ms;
    background-color: rgb(0 0 0 / 30%);
    left: 0;
    right: 0;
    .error-sidebar {
      margin: 0 2rem;
      @include themed() {
        background: t($bg);
      };
      padding: 1.5rem;
      border-radius: $border-radius;
      box-shadow: 0 0 5px 0 #00000027;
    }
  }
}

.base-app {
  min-height: 100vh;
  .home {
    height: 100vh;
    overflow: auto;
    flex: 1 1;
    padding-left: $navbar-width;
    transition: 300ms;
  }
  .home-page {
    @include media-breakpoint-up(lg) {
      .home {
        padding-right: 374px;
      }
    }
  }
  &.minimize {
    .home {
      padding-left: $navbar-width-minimize;
    }
    .navbar {
      width: $navbar-width-minimize;
      overflow: hidden;
      padding: 0 0.25rem;
      .godin-logo {
        display: none;
      }
    }
  }
  @include media-breakpoint-down(md) {
    .home {
      padding-left: 0;
      margin: 0;
      padding-bottom: 70px;
    }
    .app-content {
      border-radius: none;
      padding: 1rem;
    }
  }
}

.app-content {
  padding: 1.5rem 2rem;
  border-radius: $border-radius;
  @include themed() {
    background: t($front-bg);
  }
}

.secondary-home-sidebar {
  position: fixed;
  min-width: 374px;
  top: 0;
  right: 0;
  height: 100vh;
  overflow: auto;
}

.pushed-route {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  background-color: white;
}

.client-navbar {
  height: 70px;
  color: $secondary;
  background-color: white;
  @extend .row-flex;
}