.modal-content {
  @include themed() {
    background: t($front-bg) !important;
  };
}

.modal-header {
  font-weight: 600;
}
.close-btn {
  width: 40px;
  min-width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: $border-radius-pill;
  cursor: pointer;
  text-align: center;
  color: $gray;
  font-size: 1.15rem;
  &:hover {
    @include themed() {
      background: t("light-bg2");
    };
  }
}
.close-btn-sm {
  width: 30px;
  min-width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: $border-radius-pill;
  cursor: pointer;
  text-align: center;
  color: $gray;
  font-size: 1rem;
  &:hover {
    @include themed() {
      background: t("light-bg2");
    };
  }
}

.base-dialog {
  position: fixed;
  width: 100%;
  bottom: 0;
  display: flex;
  .dialog-body {
    animation: backInUp 600ms ease;
    margin: auto;
    background-color: #27323f;
    color: white;
    padding: 0.75rem 1.5rem;
    border-radius: $border-radius $border-radius 0 0;
    &.closing {
      animation: backOutDown 600ms ease;
    }
  }
}


.alert-dialog {
  padding: 1.5rem;
  .alert-dialog-header {
    @extend .col-center;
    font-size: 1.3rem;
    font-weight: 600;
    margin-bottom: 1.5rem;
  }

  .alert-dialog-body {
    @extend .row-center;
    text-align: center;
    margin-bottom: 1.5rem;
    white-space: pre-wrap;

  }
  .alert-dialog-footer {
    @extend .row-center;
  }
}