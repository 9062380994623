.fc {
  .fc-toolbar-title {
    text-transform: capitalize;
  }
  .fc-button-primary {
    @include themed() {
      background: t($light-bg);
      color: t($text)
    };
    border: none;
    border-radius: $border-radius;
    &.fc-button {
      &:hover, &:active, &:focus, &:active:focus {
        box-shadow: none;
        @include themed() {
          background: t("light-bg2");
        };
      }
    }
    .fc-button-active {
      &:hover, &:active, &:focus, &:active:focus {
        box-shadow: none;
        @include themed() {
          background: t("light-bg2");
        };
      }
    }
  }
  .fc-prev-button {
    margin-right: 0.5rem;
  }
  .fc-col-header-cell-cushion {
    padding: 4px;
  }
}
.fc-daygrid-body {
  @include themed() {
    border-color: t("border");
  };
}

.fc-theme-standard {
  .fc-scrollgrid {
    transition: 200ms;
    td {
      @include themed() {
        background: t($front-bg);
        color: t($text);
        border-color: t("border");
      };
    }
    th {
      @include themed() {
        border-color: t("border");
      };
    }
    &.fc-scrollgrid-liquid {
      @include themed() {
        border-color: t("border");
      };
    }
  }
}

.fc-timegrid-slot.fc-timegrid-slot-lane {
  height: 50px;
}

.fc-daygrid-event {
  cursor: pointer;
}
.fc-timeline-event {
  &.select-mode {
    cursor: pointer;
  }
  &.split-mode {
    cursor: url("../../images/cut-regular.svg"), pointer;
    &:active, &:focus, &:focus-within {
      cursor: initial;
    }
  }
  &.erase-mode {
    cursor: url("../../images/eraser-solid.svg") 10 10, pointer;
    &:active, &:focus, &:focus-within {
      cursor: initial;
    }
  }
}




.fc-timeline-event-harness {
  margin: 0 0.25rem 0.25rem 0.25rem;
  .fc-timeline-event {
    border-radius: $border-radius;
  }
  .fc-h-event {
    margin-top: 0.25rem;
    border-width: 0;
    display: block;
  }
}