

@each $key, $value in $spacers {
  .width-#{$key} {
    width: $value;
  }
  .min-width-#{$key} {
    min-width: $value;
  }
  .max-width-#{$key} {
    max-width: $value;
  }
  .height-#{$key} {
    height: $value;
  }
  .min-height-#{$key} {
    min-height: $value;
  }
  .max-height-#{$key} {
    max-height: $value;
  }
}

@each $key, $value in $spacers {
  .size-#{$key} {
    height: $value;
    width: $value;
  }
}

.pointer {
  cursor: pointer;
}

.grab {
  cursor: grab;
}

.flex-1 {
  flex: 1;
}

.row-flex {
  display: flex;
  align-items: center;
}

.row-end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.row-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.row-around {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.row-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.col-flex {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.col-between {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.col-around {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}


.col-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


