// TOOLS
@import './_variables';
@import './tools/sugar';
@import './tools/animate.scss';
// ICONS
@import '../icons/scss/fontawesome.scss';
// @import '../icons/scss/duotone.scss';
@import '../icons/scss/solid.scss';
// @import '../icons/scss/light.scss';
@import '../icons/scss/regular.scss';
@import '../icons/scss/brands.scss';

// Bootstrap
// @import '~bootstrap/scss/bootstrap';
@import "bootstrap/bootstrap-utilities.scss";

// Layout & components
@import "bootstrap/root";
@import "bootstrap/reboot";
@import "bootstrap/images";
@import "bootstrap/containers";
@import "bootstrap/grid";
@import "bootstrap/tables";
@import "bootstrap/forms";
@import "bootstrap/buttons";
@import "bootstrap/transitions";
@import "bootstrap/dropdown";
@import "bootstrap/pagination";
@import "bootstrap/nav";
@import "bootstrap/modal";
@import "bootstrap/card";
@import 'bootstrap/offcanvas';
@import "bootstrap/progress";
@import "bootstrap/badge";
@import "bootstrap/alert";
@import "bootstrap/tooltip";
@import "bootstrap/close";

// Helpers
@import "bootstrap/helpers";

// Utilities
@import "bootstrap/utilities/api";

// BASICS
@import './basics/typo.scss';
@import './basics/calendar.scss';
@import './basics/admin.scss';
@import './basics/navbar.scss';
@import './basics/layout.scss';
@import './basics/rooms.scss';
@import './basics/loading.scss';
@import './basics/toaster.scss';
@import './basics/input.scss';
@import './basics/modal.scss';
@import './basics/common.scss';
@import './basics/button.scss';
@import './basics/card.scss';
@import './basics/table.scss';
@import './basics/balloon.css';
