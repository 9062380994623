.table {
  font-size: $font-size-base;
  @include themed() {
    color: t($text);
  }
  thead {
    border-bottom: 1px solid;
    @include themed() {
      border-color: t("border");
    }
    border-radius: $border-radius-sm;
    font-size: $font-size-sm;
    th {
      @include themed() {
        color: t($navbar-text);
      }
    }
  }
  tr {
    vertical-align: middle;
  }
}

.table-hover {
  tbody {
    tr {
      cursor: pointer;
      &:hover {
        --bs-table-accent-bg: none;
        @include themed() {
          background: t($light-bg) !important;
          color: t($text);
        };
      }
    }
  }
}

.multiple-actions-bar {
  @extend .row-flex;
  padding: 0.25rem 1rem;
  position: absolute;
  @include themed() {
      background: t($front-bg);
    };
  left: 3rem;
  right: 0;
}