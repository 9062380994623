.bg-light {
  @include themed() {
    background: t($light-bg) !important;
  };
}

a:hover {
  text-decoration: none;
}
.light-hover {
  cursor: pointer;
  transition: 300ms;
  &:hover {
    @include themed() {
      background: t($light-bg);
    };
  }
}

.text-multi-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.avatar-letter {
  @extend .row-center;
  color: white;
  text-transform: uppercase;
}

.yard-show-present {
  overflow: auto;
}

.bg-image {
  background-position: center;
  background-size: cover;
}

.distance {
  padding: 0.25rem 0.5rem 0.25rem 0.5rem;
  @include themed() {
      background: t($bg);
    };
  border-radius: $border-radius 0 $border-radius 0;
  font-size: 0.8rem;
  width: fit-content;
}

.responsive-filter {
  width: 100%;
  top: 0;
  left: 0;
  position: fixed;
  @include themed() {
      background: t($bg);
    };
  box-shadow: $shadow;
  select {
    box-shadow: none;
  }
  i {
    font-size: 1.25rem;
  }
}
.page-link {
  line-height: 1;
  cursor: pointer;
}


.color-indice {
  padding: 3px;
  border-radius: 50%;
  cursor: pointer;
  &.selected {
    border: 1px solid $gray-400;
  }
  .color-indice-core {
    border-radius: 30px;
    width: 28px;
    height: 28px;
  }
  .color-indice-input {
    border-radius: 30px;
    width: 34px;
    height: 34px;
  }
}

.center-loading {
  margin: 140px 0;
  text-align: center;
}

.tooltip-container {
  position: relative;
  .tooltip {
    position: absolute;
    width: 160px;
    display: none;
    top: 100%;
    left: -80px;
    z-index: 100;
    opacity: 0;
    animation: fade 500ms ease 500ms forwards;
    border-radius: $border-radius;
    text-align: center;
    font-size: 0.8rem;
    margin-left: 1rem;
    padding: 1rem;
    @include themed() {
      color: t($text);
    };
    @include themed() {
      background: t($bg);
    };
    box-shadow: 0px 3px 8px 0 #0000001a;
  }
  &:hover {
    .tooltip {
      display: block;
    }
  }

}


.bg-front {
  @include themed() {
    background: t("front-bg") !important;
  };
}

.yard-user-deletable {
  @include themed() {
    background: t($light-bg);
  };
  border-radius: $border-radius;
  padding: 0.5rem;
}

.decorated-icon {
  @include themed() {
    background: t($light-bg);
  };
  color: $primary;
  border-radius: $border-radius;
  width: 3rem;
  height: 3rem;
  @extend .row-center;
  font-size: 1.5rem;
}

.decorated-text {
  @extend .bg-light-primary;
  color: $primary;
  border-radius: $border-radius;
  @extend .row-center;
}

.doughnut-text {
  position: absolute;
  @extend .translate-middle-y;
  width: 100%;
  text-align: center;
  top: 53%;
}

.gradient-bg {
  background: linear-gradient(
0deg, #3275a3, #7ecad2);
}

.pagination {
  margin: 0 -0.125rem;
  .page-item .page-link {
    margin: 0 0.125rem;
    border-radius: $border-radius !important;
    height: 2.25rem;
    width: 2.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    @include themed() {
      background-color: t($light-bg);
      border-color: t("border");
      color: t("text");
    }
  }
}


.media-file {
  position: absolute;
  opacity: 0;
  width: 0;
  visibility: hidden;
  height: 0;
}


.tabs {
  display: flex;
  justify-content: space-around;
  padding: 0.25rem 0;
  align-items: flex-end;
  position: relative;
  min-height: 2.5rem;
  font-weight: 600;
  .tab-link {
    text-align: center;
    width: 50%;
    cursor: pointer;
    &.active {
      color: $primary;
    }
    &:hover {
      color: $primary;
    }
  }
  .tab-indicator {
    height: 2px;
    position: absolute;
    width: 50%;
    bottom: 0;
    left: 0;
    display: flex;
    left: 50%;
    transition: 300ms;
    .i-content {
      height: 100%;
      width: 100%;
      background-color: $primary;
    }
  }
}
.tab-content {
  padding: 1rem 0;
  max-height: -webkit-fill-available;
  overflow: auto;
}

.light-tabs {
  color: $primary;
  .tabs {
    @extend .row-between;
    .left-tabs, .right-tabs {
      @extend .row-flex;
      width: 38%;
    }
  }
  .post-card.card.elevation {
    box-shadow: none;
  }
}

.capitalize {
  text-transform: capitalize;
}

.map {
  div div {
    // border-radius: $border-radius;
  }
  .custom-marker {
    cursor: pointer;
    transform: translate(-50%, -50%);
    padding: 1rem;
    i {
      font-size: 1.75rem;
    }
    &.selected {
      i {
        color: $primary;
      }
    }
  }
}
