// animating icons
// --------------------------

.#{$fa-css-prefix}-spin {
  animation-name: fa-spin;
  animation-direction: var(--#{$fa-css-prefix}-spin-direction, normal);
  animation-duration: var(--#{$fa-css-prefix}-spin-duration, 2s);
  animation-iteration-count: var(--#{$fa-css-prefix}-spin-iteration-count, infinite);
  animation-timing-function: var(--#{$fa-css-prefix}-spin-timing, linear);
}

.#{$fa-css-prefix}-spin-reverse {
  --#{$fa-css-prefix}-spin-direction: reverse;
}

.#{$fa-css-prefix}-pulse,
.#{$fa-css-prefix}-spin-pulse {
  animation-name: fa-spin;
  animation-direction: var(--#{$fa-css-prefix}-spin-direction, normal);
  animation-duration: var(--#{$fa-css-prefix}-spin-duration, 1s);
  animation-iteration-count: var(--#{$fa-css-prefix}-spin-iteration-count, infinite);
  animation-timing-function: var(--#{$fa-css-prefix}-spin-timing, steps(8));
}

@keyframes fa-spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
