
a {
  text-decoration: none;
  color: inherit;
}

.text-s {
  font-size: $font-size-sm;
}
.text-xs {
  font-size: 0.65rem;
  line-height: 1;
}
.text-m {
  font-size: $font-size-base;
}
.text-l {
  font-size: $font-size-lg;
}


.big-title {
  font-size: 4rem;
  font-weight: bold;
}

.text-2 {
  font-size: 2rem * .8;
}
.text-25 {
  font-size: 2.5rem * .8;
}
.text-3 {
  font-size: 3rem * .8;
}
.text-35 {
  font-size: 3.5rem * .8;
}

.text-medium-light {
  color: $gray-200;
}
.bg-medium-light {
  background-color: $gray-200 !important;
}

.bg-opacity {
  background-color: rgba($dark, 0.3);
}

.bg-light-primary {
  background-color: rgba($primary, 0.1);;
}

.bg-light-warning {
  background-color: rgba($warning, 0.1);;
}

.bg-light-danger {
  background-color: rgba($danger, 0.1);;
}

.bg-light-success {
  background-color: rgba($success, 0.1);;
}

.link {
  cursor: pointer;
  &:hover, &.active {
    color: $primary;
  }
}

.text-saturate-gray {
  color: $saturate-gray;
}
